import { SingleEventData } from '../events/EventModels';

export default async function fetchEventByID(
    eventID: number,
): Promise<SingleEventData> {
    const response: Response = await fetch(
        process.env.REACT_APP_WHERE_TO_GO_API_URL +
            'venues/club_event/' +
            eventID,
        {
            method: 'GET',
            headers: {
                Authorization:
                    'Token ' + process.env.REACT_APP_WHERE_TO_GO_API_TOKEN,
            },
        },
    );

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }

    return await response.json();
}
