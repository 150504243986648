import React, { useState, useEffect } from 'react';
import { Params, useParams } from 'react-router-dom';

import { EventHolder, SingleEventData } from './EventModels';
import fetchEventByID from '../networking/FetchEventData';

export default function EventPage(): React.JSX.Element {
    const params: Readonly<Params> = useParams();
    const eventId: string | undefined = params.eventID;

    const [event, setEvent] = useState<EventHolder | null>(null);

    useEffect(() => {
        const fetchEventData = async (): Promise<void> => {
            const eventID = Number(eventId);
            const eventDataPromise: Promise<SingleEventData> =
                fetchEventByID(eventID);
            const data: SingleEventData = await eventDataPromise;
            setEvent(() => data.club_event);
        };

        // eslint-disable-next-line @typescript-eslint/no-empty-function
        fetchEventData().then(() => {});
    }, [eventId]);

    return event ? generateResponse(event) : <div>Loading...</div>;
}

function generateResponse(event: EventHolder): React.JSX.Element {
    if (!event || !event.fields) {
        return <div>Loading...</div>;
    }

    return <div>{event.fields.name}</div>;
}
