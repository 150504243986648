import { SingleVenueData } from './VenueModels';

export default async function fetchClubByID(
    venueID: number,
): Promise<SingleVenueData> {
    const response: Response = await fetch(
        process.env.REACT_APP_WHERE_TO_GO_API_URL + 'venues/venue/' + venueID,
        {
            method: 'GET',
            headers: {
                Authorization:
                    'Token ' + process.env.REACT_APP_WHERE_TO_GO_API_TOKEN,
            },
        },
    );

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }

    return await response.json();
}
