import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, NavigateFunction } from 'react-router-dom';
import {
    MusicalNoteIcon,
    CalendarDaysIcon,
    NewspaperIcon,
} from '@heroicons/react/20/solid';
import { CityHolder, fetchCityBySlug } from './networking/FetchCityData';

export default function CityPage(): React.JSX.Element {
    const { slug } = useParams();
    const navigate = useNavigate();

    const [cityData, setCityData] = useState<CityHolder | null>(null);

    useEffect(() => {
        const fetchCityData = async () => {
            const citySlug = String(slug);
            const cityDataPromise = fetchCityBySlug(citySlug);
            const data = await cityDataPromise;
            setCityData(() => data.city);
        };

        // TODO I think there is duplication here
        // Understand this
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        fetchCityData().then(() => {});
    }, [slug]);

    return cityData ? (
        GenerateResponse(cityData, navigate)
    ) : (
        <div>Loading...</div>
    );
}

function GenerateResponse(cityData: CityHolder, navigate: NavigateFunction) {
    if (!cityData || !cityData.fields) {
        return <div>Loading...</div>;
    }

    return (
        <div className="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32 w-full">
            <div className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl">
                <div
                    className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
                    style={{
                        clipPath:
                            'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                    }}
                />
            </div>
            <div className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu">
                <div
                    className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
                    style={{
                        clipPath:
                            'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                    }}
                />
            </div>
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-6 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-8">
                    {/*Clubs*/}
                    {/* TODO Fix PK */}
                    {cityData.fields.club_flag ? (
                        <div
                            key={'clubs'}
                            onClick={() =>
                                navigate(
                                    `/wheretogo/city/${cityData.fields.slug}/clubs/`,
                                    { state: { cityData } },
                                )
                            } // Navigate to clubs page
                            className="flex gap-x-4 rounded-xl bg-white/5 p-6 ring-1 ring-inset ring-white/10 hover:bg-gray-700"
                        >
                            <MusicalNoteIcon
                                className="h-7 w-5 flex-none text-indigo-400"
                                aria-hidden="true"
                            />
                            <div className="text-base leading-7">
                                <h3 className="font-semibold text-white">
                                    {cityData.fields.name} Clubs
                                </h3>
                                <p className="mt-2 text-gray-300">
                                    {cityData.fields.country}
                                </p>
                            </div>
                        </div>
                    ) : (
                        <div
                            key={'clubs'}
                            className="flex gap-x-4 rounded-xl bg-white/5 p-6 ring-1 ring-inset ring-white/10"
                        >
                            <MusicalNoteIcon
                                className="h-7 w-5 flex-none text-indigo-400"
                                aria-hidden="true"
                            />
                            <div className="text-base leading-7">
                                <h3 className="font-semibold text-white">
                                    {cityData.fields.name} Clubs
                                </h3>
                                <p className="mt-2 text-gray-300">
                                    Clubs Coming Soon
                                </p>
                            </div>
                        </div>
                    )}

                    {/*Bars*/}
                    {cityData.fields.bar_flag ? (
                        <div
                            key={'bars'}
                            onClick={() =>
                                navigate(
                                    `/wheretogo/city/${cityData.fields.slug}/bars/`,
                                    { state: { cityData } },
                                )
                            } // Navigate to clubs page
                            className="flex gap-x-4 rounded-xl bg-white/5 p-6 ring-1 ring-inset ring-white/10 hover:bg-gray-700"
                        >
                            <NewspaperIcon
                                className="h-7 w-5 flex-none text-indigo-400"
                                aria-hidden="true"
                            />
                            <div className="text-base leading-7">
                                <h3 className="font-semibold text-white">
                                    {cityData.fields.name} Bars
                                </h3>
                                <p className="mt-2 text-gray-300">
                                    {cityData.fields.country}
                                </p>
                            </div>
                        </div>
                    ) : (
                        <div
                            key={'bars'}
                            className="flex gap-x-4 rounded-xl bg-white/5 p-6 ring-1 ring-inset ring-white/10"
                        >
                            <NewspaperIcon
                                className="h-7 w-5 flex-none text-indigo-400"
                                aria-hidden="true"
                            />
                            <div className="text-base leading-7">
                                <h3 className="font-semibold text-white">
                                    {cityData.fields.name} Bars
                                </h3>
                                <p className="mt-2 text-gray-300">
                                    Bars Coming Soon
                                </p>
                            </div>
                        </div>
                    )}

                    {/*Events*/}
                    {cityData.fields.event_flag ? (
                        <div
                            key={cityData.fields.name}
                            onClick={() =>
                                navigate(
                                    `/wheretogo/city/${cityData.fields.slug}/bars/`,
                                    { state: { cityData } },
                                )
                            } // Navigate to clubs page
                            className="flex gap-x-4 rounded-xl bg-white/5 p-6 ring-1 ring-inset ring-white/10 hover:bg-gray-700"
                        >
                            <CalendarDaysIcon
                                className="h-7 w-5 flex-none text-indigo-400"
                                aria-hidden="true"
                            />
                            <div className="text-base leading-7">
                                <h3 className="font-semibold text-white">
                                    {cityData.fields.name} Events
                                </h3>
                                <p className="mt-2 text-gray-300">
                                    {cityData.fields.country}
                                </p>
                            </div>
                        </div>
                    ) : (
                        <div
                            key={cityData.fields.name}
                            className="flex gap-x-4 rounded-xl bg-white/5 p-6 ring-1 ring-inset ring-white/10"
                        >
                            <CalendarDaysIcon
                                className="h-7 w-5 flex-none text-indigo-400"
                                aria-hidden="true"
                            />
                            <div className="text-base leading-7">
                                <h3 className="font-semibold text-white">
                                    {cityData.fields.name} Events
                                </h3>
                                <p className="mt-2 text-gray-300">
                                    Events Coming Soon
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
