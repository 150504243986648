import HomepageHeader from './homepage/Header';

export default function Home() {
    return (
        // <div>
        //     {/*<h2>Home</h2>*/}
        //     <HomepageHeader/>
        // </div>
        <html className="h-full">
            <body className="h-full">
                {/*<h2>Home</h2>*/}
                <HomepageHeader />
            </body>
        </html>
    );
}
