import React, { useEffect, useState } from 'react';

import {
    FeaturedResponseData,
    fetchFeaturedDataFromAPI,
} from '../networking/FetchFeatured';
import { CardContents, CardDisplay } from '../generic/CardDisplay';
import Divider from '../generic/Divider';

export default function FeaturedComponent(): React.JSX.Element {
    const [featuredData, setFeaturedData] = useState<FeaturedResponseData | null>(
        null,
    );

    useEffect(() => {
        const fetchFeaturedData = async () => {
            const featuredDataPromise = fetchFeaturedDataFromAPI();
            const data = await featuredDataPromise;

            setFeaturedData(() => data);
        };

        // eslint-disable-next-line @typescript-eslint/no-empty-function
        fetchFeaturedData().then(() => {});
    }, []);

    return featuredData ? (
        generateResponse(featuredData)
    ) : (
        <div>Loading...</div>
    );
}

function generateResponse(
    featuredData: FeaturedResponseData,
): React.JSX.Element {
    if (!featuredData) {
        return <div>Loading...</div>;
    }

    // Use the data
    console.log(featuredData);

    // TODO Extract this to a component
    return buildCardContents(featuredData);
}

function buildCardContents(
    featuredData: FeaturedResponseData,
): React.JSX.Element {
    const featuredActivities: CardContents[] = [];
    const featuredEvents: CardContents[] = [];

    // Loop through the featured_activities

    // TODO Check that the models line up with the data being returned
    featuredData.featured_activities.forEach((activity) => {
        const cardContent: CardContents = {
            title: activity.name,
            description: activity.description,
            city: activity.city_name,
            navigateTo: `/wheretogo/city/${activity.city_slug}/activity/${activity.id}`,
        };

        featuredActivities.push(cardContent);
    });

    // Loop through the featured_events
    featuredData.featured_club_events.forEach((event) => {
        const cardContent: CardContents = {
            title: event.name,
            description: event.description,
            city: event.city_name,
            navigateTo: `/wheretogo/city/${event.city_slug}/event/${event.id}`, // TODO check the nav
        };

        featuredEvents.push(cardContent);
    });

    // TODO Clean this up
    return (
        <div>
            <div>
                {/*<h2>Featured Activities</h2>*/}
                <Divider title={'Featured Activities'} />
                <CardDisplay
                    headerText={'Featured Activities'}
                    data={featuredActivities}
                />
            </div>
            <div>
                {/*<h2>Featured Events</h2>*/}
                <Divider title={'Featured Events'} />
                <CardDisplay
                    headerText={'Featured Events'}
                    data={featuredEvents}
                />
            </div>
        </div>
    );
}
