import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ActivityHolder, SingleActivitiesData } from './ActivityModels';
import fetchActivityByID from '../networking/FetchActivityData';

export default function ActivityPage(): React.JSX.Element {
    const params = useParams();
    const activityId = params.activityID;

    const [activity, setActivity] = useState<ActivityHolder | null>(null);

    useEffect(() => {
        const fetchActivityData = async (): Promise<void> => {
            const activityID = Number(activityId);
            const activityDataPromise: Promise<SingleActivitiesData> =
                fetchActivityByID(activityID);
            const data: SingleActivitiesData = await activityDataPromise;
            setActivity(() => data.activity);
        };

        // eslint-disable-next-line @typescript-eslint/no-empty-function
        fetchActivityData().then(() => {});
    }, [activityId]);

    return activity ? generateResponse(activity) : <div>Loading...</div>;
}

// TODO Cleanup loading so it's in line with design
function generateResponse(activity: ActivityHolder): React.JSX.Element {
    if (!activity || !activity.fields) {
        return <div>Loading...</div>;
    }

    return <div>{activity.fields.name}</div>;
}
