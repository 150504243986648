interface City {
    name: string;
    country: string;
    slug: string;
    image: string; // TODO still broken
    club_flag: boolean;
    bar_flag: boolean;
    event_flag: boolean;
    sports_flag: boolean;
    social_flag: boolean;
    active: boolean;
    date_closed: string;
}

export interface CityHolder {
    model: string;
    pk: number;
    fields: City;
}

export interface CityData {
    city: CityHolder;
}

export async function fetchCityBySlug(citySlug: string): Promise<CityData> {
    const response: Response = await fetch(
        process.env.REACT_APP_WHERE_TO_GO_API_URL + 'venues/city/' + citySlug,
        {
            method: 'GET',
            headers: {
                Authorization:
                    'Token ' + process.env.REACT_APP_WHERE_TO_GO_API_TOKEN,
            },
        },
    );

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }

    return await response.json();
}
