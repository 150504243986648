import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ClubNightsComponent from '../networking/FetchClubNightsData';
import { GoogleMapFromURL } from '../GoogleMap';
import fetchClubByID from '../networking/FetchClubData';
import Divider from '../generic/Divider';

import { VenueHolder } from '../networking/VenueModels';

// TODO Cleanup and check other pages for similar issues
export default function CityNightsPage(): React.JSX.Element {
    const params = useParams();
    const clubId = params.clubID; // Taken from params

    const [club, setClub] = useState<VenueHolder | null>(null);

    useEffect(() => {
        const fetchClubData = async () => {
            const venueID = Number(clubId);
            const venueDataPromise = fetchClubByID(venueID);
            const data = await venueDataPromise;
            setClub(() => data.venue);
        };

        // eslint-disable-next-line @typescript-eslint/no-empty-function
        fetchClubData().then(() => {});
    }, [clubId]);

    return club ? generateResponse(club) : <div>Loading...</div>;
}

// TODO Move to a component
function generateResponse(club: VenueHolder): React.JSX.Element {
    if (!club || !club.fields) {
        return <div>Loading...</div>;
    }

    return (
        <div className="w-3/4">
            <div className="overflow-hidden rounded-lg bg-gray-800 shadow">
                {/*} TODO This is so ugly */}
                <div className="px-4 py-5 sm:p-6">
                    {club.fields.name} Club Nights
                </div>
            </div>

            <div className="overflow-hidden rounded-lg bg-gray-800 shadow">
                <div className="px-4 py-5 sm:p-6 text-sm">
                    {club.fields.description}
                </div>
            </div>

            {/* Need Google API key to do this way */}
            {/*<GoogleMap latitude={club.fields.latitude} longitude={club.fields.longitude}/>*/}
            <GoogleMapFromURL googleMapURL={club.fields.google_maps_link} />
            <Divider title={'Scheduled Club Nights'} />
            {/* TODO This needs to be extracted from the response and generalised */}
            <ClubNightsComponent clubId={club.pk} />
            <Divider title={'Club Events'} />
        </div>
    );
}
