import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';

import './App.css';

import Home from './components/Home';
import About from './components/About';
import WhereToGo from './components/WhereToGo';
import NavBar from './components/homepage/NavBar';
import CityPage from './components/CityPage';
import CityClubsPage from './components/clubs/CityClubsPage';
import CityNightsPage from './components/clubs/ClubNightsPage';
import Footer from './components/homepage/Footer';
import PageNotFoundPage from './components/404';
import ActivityPage from './components/activities/ActivityPage';
import EventPage from './components/events/EventPage';
import CityBarsPage from './components/bars/CityBarsPage';

// Temp for testing

const queryClient = new QueryClient();

function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <Router>
                <div className="App">
                    <NavBar />
                    {/*<NavigationExample/>*/}
                    <header className="App-header">
                        {/*<img src={logo} className="App-logo" alt="logo"/>*/}
                        {/*<p>*/}
                        {/*    The Yam Network*/}
                        {/*</p>*/}
                        <Routes>
                            {/* Generic */}
                            <Route path="/about" element={<About />}></Route>
                            {/* WhereToGo */}
                            <Route
                                path="/wheretogo"
                                element={<WhereToGo />}
                            ></Route>
                            <Route
                                path="/wheretogo/city/:slug"
                                element={<CityPage />}
                            ></Route>
                            <Route
                                path="/wheretogo/city/:slug/clubs"
                                element={<CityClubsPage />}
                            ></Route>
                            <Route
                                path="/wheretogo/city/:slug/bars"
                                element={<CityBarsPage />} // Change to bars
                            ></Route>
                            <Route
                                path="/wheretogo/city/:slug/clubs/:clubID"
                                element={<CityNightsPage />}
                            ></Route>
                            <Route
                                path="/wheretogo/city/:slug/activity/:activityID"
                                element={<ActivityPage />}
                            ></Route>
                            <Route
                                path="/wheretogo/city/:slug/event/:eventID"
                                element={<EventPage />}
                            ></Route>
                            {/* Root */}
                            <Route path="/" element={<Home />}></Route>
                            <Route path="*" element={<PageNotFoundPage />} />
                        </Routes>
                    </header>
                    <Footer />
                </div>
            </Router>
        </QueryClientProvider>
    );
}

export default App;
