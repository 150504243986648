import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ClubsByCityComponent from '../networking/FetchClubsByCityData';
import { CityHolder, fetchCityBySlug } from '../networking/FetchCityData';

/**
 * CityClubsPage Component
 * @returns {React.ReactElement} - Returns a React element
 */
export default function CityClubsPage(): React.ReactElement {
    const slug = String(useParams().slug);

    const [cityData, setCityData] = React.useState<CityHolder | null>(null);

    // TODO This is pasta, generalise
    useEffect(() => {
        const fetchCityData = async () => {
            const citySlug = String(slug);
            const cityDataPromise = fetchCityBySlug(citySlug);
            const data = await cityDataPromise;
            setCityData(() => data.city);
        };

        // eslint-disable-next-line @typescript-eslint/no-empty-function
        fetchCityData().then(() => {});
    }, [slug]);

    if (!cityData) {
        return <div>A Problem has occurred</div>;
    }

    return (
        <div className="w-3/4">
            <h1>{cityData.fields.name}</h1>
            <h2>City Clubs</h2>
            <ClubsByCityComponent citySlug={slug} />
        </div>
    );
}
