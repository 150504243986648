import { Disclosure } from '@headlessui/react';
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Link, NavLink } from 'react-router-dom';
import React from 'react';
import ProfileDropdown from '../menu_components/ProfileDropdown';

export default function NavBar() {
    return (
        <Disclosure as="nav" className="bg-gray-900">
            {({ open }) => (
                <>
                    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                        <div className="flex h-16 items-center justify-between">
                            <div className="flex items-center">
                                <div className="flex-shrink-0">
                                    <Link to="/">
                                        <img
                                            className="h-8 w-auto"
                                            src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                                            alt="The Yam Network"
                                        />
                                    </Link>
                                </div>
                                <div className="hidden sm:ml-6 sm:block">
                                    <div className="flex space-x-4">
                                        {/* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" */}
                                        <NavLink
                                            to="/"
                                            // className="rounded-md bg-gray-900 px-3 py-2 text-sm font-medium text-white"
                                            className="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
                                            // activeClassName="rounded-md bg-gray-900 px-3 py-2 text-sm font-medium text-white" TODO: Fix activeClassName
                                        >
                                            Home
                                        </NavLink>
                                        <NavLink
                                            to="/about"
                                            className="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
                                            // activeClassName="rounded-md bg-gray-900 px-3 py-2 text-sm font-medium text-white"
                                        >
                                            About
                                        </NavLink>
                                        <NavLink
                                            to="/wheretogo"
                                            className="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
                                        >
                                            WhereToGo
                                        </NavLink>
                                        {/*<a*/}
                                        {/*    href="#"*/}
                                        {/*    className="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white"*/}
                                        {/*>*/}
                                        {/*    Projects*/}
                                        {/*</a>*/}
                                        {/*<a*/}
                                        {/*    href="#"*/}
                                        {/*    className="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white"*/}
                                        {/*>*/}
                                        {/*    Calendar*/}
                                        {/*</a>*/}
                                    </div>
                                </div>
                            </div>
                            {/* How the fuck do I put this in the middle*/}
                            <div className="rounded-lg px-3 text-lg font-medium, text-gray-300">
                                The Yam Network
                            </div>
                            {/*<div className="hidden sm:ml-6 sm:block">*/}
                            {/*    <div className="flex items-center">*/}
                            {/*        <button*/}
                            {/*            type="button"*/}
                            {/*            className="relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"*/}
                            {/*        >*/}
                            {/*            <span className="absolute -inset-1.5" />*/}
                            {/*            <span className="sr-only">View notifications</span>*/}
                            {/*            <BellIcon className="h-6 w-6" aria-hidden="true" />*/}
                            {/*        </button>*/}

                            {/*        <ProfileDropdown/>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div className="-mr-2 flex sm:hidden">
                                {/* Mobile menu button */}
                                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                    <span className="absolute -inset-0.5" />
                                    <span className="sr-only">
                                        Open main menu
                                    </span>
                                    {open ? (
                                        <XMarkIcon
                                            className="block h-6 w-6"
                                            aria-hidden="true"
                                        />
                                    ) : (
                                        <Bars3Icon
                                            className="block h-6 w-6"
                                            aria-hidden="true"
                                        />
                                    )}
                                </Disclosure.Button>
                            </div>
                        </div>
                    </div>

                    <Disclosure.Panel className="sm:hidden">
                        <div className="space-y-1 px-2 pb-3 pt-2">
                            {/* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" */}
                            {/*<Disclosure.Button*/}
                            {/*    as="a"*/}
                            {/*    href="#"*/}
                            {/*    className="block rounded-md bg-gray-900 px-3 py-2 text-base font-medium text-white"*/}
                            {/*>*/}
                            {/*    Dashboard*/}
                            {/*</Disclosure.Button>*/}
                            <Disclosure.Button
                                as={NavLink}
                                to={'/wheretogo'}
                                className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
                            >
                                WhereToGo
                            </Disclosure.Button>
                            <Disclosure.Button
                                as={NavLink}
                                to={'/about'}
                                className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
                            >
                                About
                            </Disclosure.Button>
                            {/*<Disclosure.Button*/}
                            {/*    as="a"*/}
                            {/*    href="#"*/}
                            {/*    className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"*/}
                            {/*>*/}
                            {/*    Calendar*/}
                            {/*</Disclosure.Button>*/}
                        </div>
                        {/*<div className="border-t border-gray-700 pb-3 pt-4">*/}
                        {/*    <div className="flex items-center px-5">*/}
                        {/*        <div className="flex-shrink-0">*/}
                        {/*            <img*/}
                        {/*                className="h-10 w-10 rounded-full"*/}
                        {/*                src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"*/}
                        {/*                alt=""*/}
                        {/*            />*/}
                        {/*        </div>*/}
                        {/*        <div className="ml-3">*/}
                        {/*            <div className="text-base font-medium text-white">Tom Cook</div>*/}
                        {/*            <div className="text-sm font-medium text-gray-400">tom@example.com</div>*/}
                        {/*        </div>*/}
                        {/*        <button*/}
                        {/*            type="button"*/}
                        {/*            className="relative ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"*/}
                        {/*        >*/}
                        {/*            <span className="absolute -inset-1.5" />*/}
                        {/*            <span className="sr-only">View notifications</span>*/}
                        {/*            <BellIcon className="h-6 w-6" aria-hidden="true" />*/}
                        {/*        </button>*/}
                        {/*    </div>*/}
                        {/*    <div className="mt-3 space-y-1 px-2">*/}
                        {/*        <Disclosure.Button*/}
                        {/*            as="a"*/}
                        {/*            href="#"*/}
                        {/*            className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"*/}
                        {/*        >*/}
                        {/*            Your Profile*/}
                        {/*        </Disclosure.Button>*/}
                        {/*        <Disclosure.Button*/}
                        {/*            as="a"*/}
                        {/*            href="#"*/}
                        {/*            className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"*/}
                        {/*        >*/}
                        {/*            Settings*/}
                        {/*        </Disclosure.Button>*/}
                        {/*        <Disclosure.Button*/}
                        {/*            as="a"*/}
                        {/*            href="#"*/}
                        {/*            className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"*/}
                        {/*        >*/}
                        {/*            Sign out*/}
                        {/*        </Disclosure.Button>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    );
}
