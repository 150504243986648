// TODO Move the models to a separate file
// interface FeaturedActivities{
//     featured_activities: FeaturedActivitiesHolder[];
// }
//
// interface FeaturedClubEvents{
//     featured_events: FeaturedEventsHolder[];
// }

interface FeaturedActivitiesHolder {
    model: string;
    pk: number;
    fields: FeaturedActivitiesData;
}

interface FeaturedClubEventsHolder {
    model: string;
    pk: number;
    fields: FeaturedEventsData;
}

interface FeaturedActivitiesData {
    id: number;
    name: string;
    city: number;
    city_name: string;
    city_slug: string;
    description: string;
    type: string;
}

interface FeaturedEventsData {
    id: number;
    name: string;
    venue: number;
    description: string;
    city_name: string;
    city_slug: string;
    date: string;
}

export interface FeaturedResponseData {
    featured_activities: FeaturedActivitiesData[];
    featured_club_events: FeaturedEventsData[];
}

export async function fetchFeaturedDataFromAPI(): Promise<FeaturedResponseData> {
    const response: Response = await fetch(
        process.env.REACT_APP_WHERE_TO_GO_API_URL + 'activities/featured/',
        {
            method: 'GET',
            headers: {
                Authorization:
                    'Token ' + process.env.REACT_APP_WHERE_TO_GO_API_TOKEN,
            },
        },
    );

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }

    return await response.json();
}
