import React from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {
    LockOpenIcon,
    LockClosedIcon,
    MusicalNoteIcon,
} from '@heroicons/react/20/solid';

interface ClubNightData {
    club_nights: ClubNightHolder[];
}

interface ClubNightHolder {
    model: string;
    pk: number;
    fields: ClubNight;
}

interface ClubNight {
    club: number;
    name: string;
    description: string;
    day_of_week: string;
    start_time: string;
    end_time: string;

    active: boolean;
}

async function fetchClubNightsData(clubId: number): Promise<ClubNightData> {
    const response = await fetch(
        process.env.REACT_APP_WHERE_TO_GO_API_URL +
            'venues/clubs/' +
            clubId +
            '/club_nights/',
        {
            method: 'GET',
            headers: {
                Authorization:
                    'Token ' + process.env.REACT_APP_WHERE_TO_GO_API_TOKEN,
            },
        },
    );

    console.log('Club ID: ' + clubId);

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }

    return await response.json();
}

// TODO Unsure why this doesn't work
// interface ClubNightsComponentProps {
//     clubId: number;
// }

export default function ClubNightsComponent({ clubId }: any) {
    const navigate = useNavigate();
    const { data, status } = useQuery('club_nights', () =>
        fetchClubNightsData(clubId),
    );

    if (status === 'loading') {
        return <div>Loading...</div>;
    }

    if (status === 'error') {
        return <div>Error fetching data</div>;
    }

    // Pure and utter jank
    // TODO Fix the wrapping at least
    // Make it less ugly
    return (
        <ul
            role="list"
            className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 py-5"
        >
            {data &&
                data.club_nights.map(
                    (club_night: ClubNightHolder, index: number) => (
                        <li
                            key={club_night.pk}
                            className="col-span-1 divide-y divide-gray-200 bg-gray-800 rounded-lg shadow"
                        >
                            <div className="flex w-full items-center justify-between space-x-6 p-6">
                                <div className="flex-1 truncate">
                                    <div className="flex items-center space-x-3">
                                        <h3 className="truncate text-sm font-medium text-white">
                                            {club_night.fields.name}
                                        </h3>
                                        <span className="inline-flex flex-shrink-0 items-center rounded-full bg-green-50 px-1.5 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                            {club_night.fields.day_of_week}
                                        </span>
                                    </div>
                                    <p className="mt-1 text-wrap text-sm text-gray-500">
                                        {club_night.fields.description}
                                    </p>
                                </div>
                                {/*<img className='h-10 w-10 flex-shrink-0 rounded-full bg-gray-300' src={demoImageUrl} alt=''/>*/}
                                <MusicalNoteIcon className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-300" />
                            </div>
                            <div>
                                <div className="-mt-px flex divide-x divide-gray-200">
                                    <div className="flex w-0 flex-1">
                                        <a className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900">
                                            <LockOpenIcon
                                                className="h-5 w-5 text-gray-400"
                                                aria-hidden="true"
                                            />
                                            <p className={'text-gray-500'}>
                                                {club_night.fields.start_time}
                                            </p>
                                        </a>
                                    </div>
                                    <div className="-ml-px flex w-0 flex-1">
                                        <a className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900">
                                            <LockClosedIcon
                                                className="h-5 w-5 text-gray-400"
                                                aria-hidden="true"
                                            />
                                            <p className={'text-gray-500'}>
                                                {club_night.fields.end_time}
                                            </p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                    ),
                )}
        </ul>
    );
}
