import { SingleActivitiesData } from '../activities/ActivityModels';

export default async function fetchActivityByID(
    activityID: number,
): Promise<SingleActivitiesData> {
    const response: Response = await fetch(
        process.env.REACT_APP_WHERE_TO_GO_API_URL +
            'activities/activity/' +
            activityID,
        {
            method: 'GET',
            headers: {
                Authorization:
                    'Token ' + process.env.REACT_APP_WHERE_TO_GO_API_TOKEN,
            },
        },
    );

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }

    return await response.json();
}
