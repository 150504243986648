interface DividerProps {
    title: string;
}

export default function Divider({ title }: DividerProps) {
    return (
        <div className="relative py-5">
            <div
                className="absolute inset-0 flex items-center"
                aria-hidden="true"
            >
                <div className="w-full border-t border-gray-300" />
            </div>
            <div className="relative flex justify-center">
                <span className="bg-white px-3 text-base font-semibold leading-6 text-gray-900 rounded-lg">
                    {title}
                </span>
            </div>
        </div>
    );
}
