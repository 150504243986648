import CityComponent from './networking/FetchCitiesData';
import FeaturedComponent from './activities/FeaturedComponent';

// WhereToGo Page
export default function WhereToGo() {
    return (
        <div>
            <CityComponent />
            {/*<FeaturedComponent/>*/}
            {/*<FeaturedComponent>*/}
        </div>
    );
}
