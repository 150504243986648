import BuildGoogleMapURL from '../utils/GoogleMaps';
import { MapPinIcon } from '@heroicons/react/20/solid';

interface GoogleMapProps {
    latitude: number;
    longitude: number;
}

interface GoogleMapFromUrlProps {
    googleMapURL: string;
}

export function GoogleMap({ latitude, longitude }: GoogleMapProps) {
    // TODO This is static for testing
    // let googleMapURL = BuildGoogleMapURL(latitude, longitude);
    const googleMapURL =
        'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2482.073013073073!2d-0.1276476842292927!3d51.507321220396!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487604ebe0b3d9c5%3A0x1e3b3d4b5d7f4b2e!2sBuckingham%20Palace!5e0!3m2!1sen!2suk!4v1633663660734!5m2!1sen!2suk';

    return (
        <div className="w-full h-64 py-5">
            <iframe
                className={'w-full h-full rounded-xl'}
                src={googleMapURL}
                allowFullScreen={true}
                loading={'lazy'}
            ></iframe>
        </div>
    );
}

export function GoogleMapFromURL({ googleMapURL }: GoogleMapFromUrlProps) {
    // TODO This is static for testing
    // let googleMapURL = BuildGoogleMapURL(latitude, longitude);
    // let googleMapURL = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2482.073013073073!2d-0.1276476842292927!3d51.507321220396!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487604ebe0b3d9c5%3A0x1e3b3d4b5d7f4b2e!2sBuckingham%20Palace!5e0!3m2!1sen!2suk!4v1633663660734!5m2!1sen!2suk";

    return (
        <div className="w-full h-32 py-5">
            <a
                className={
                    'w-full h-full rounded-xl bg-gray-800 flex items-center justify-center hover:bg-gray-700'
                }
                href={googleMapURL}
                target={'_blank'}
                rel={'noopener noreferrer'}
            >
                <p>Open in Google Maps</p>
                <MapPinIcon className={'w-1/4 h-1/4'}></MapPinIcon>
            </a>

            {/*  Can't do this in an iframe due to X-FRAME-OPTIONS on Google side */}
            {/*</div>*/}
            {/*<iframe*/}
            {/*    className={"w-full h-full rounded-xl"}*/}
            {/*    src={googleMapURL}*/}
            {/*    allowFullScreen={true}*/}
            {/*    loading={"lazy"}*/}
            {/*></iframe>*/}
        </div>
    );
}
