import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { VenuesData, VenueHolder } from './VenueModels';

async function fetchClubsByCityData(citySlug: string): Promise<VenuesData> {
    const response = await fetch(
        process.env.REACT_APP_WHERE_TO_GO_API_URL +
            'venues/cities/' +
            citySlug +
            '/clubs',
        {
            method: 'GET',
            headers: {
                Authorization:
                    'Token ' + process.env.REACT_APP_WHERE_TO_GO_API_TOKEN,
            },
        },
    );

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }

    return await response.json();
}

// This is jank, find a better way
interface ClubsByCityComponentProps {
    citySlug: string;
}

export default function ClubsByCityComponent({
    citySlug,
}: ClubsByCityComponentProps) {
    const navigate = useNavigate();
    const { data, status } = useQuery('clubs', () =>
        fetchClubsByCityData(citySlug),
    );

    if (status === 'loading') {
        return <div>Loading...</div>;
    }

    if (status === 'error') {
        return <div>Error fetching data</div>;
    }

    // TODO Clean up and make nice
    return (
        <ul role="list" className="divide-y divide-gray-800">
            {data &&
                data.clubs.map((club: VenueHolder, index: number) => (
                    <li
                        key={club.pk}
                        className="flex justify-between gap-x-6 py-5"
                        onClick={() =>
                            navigate(
                                `/wheretogo/city/${citySlug}/clubs/${club.pk}`,
                                { state: { club } },
                            )
                        }
                    >
                        <div className="flex min-w-0 gap-x-4">
                            {/*<img className='h-12 w-12 flex-none rounded-full bg-gray-800' src={} alt=''/>*/}
                            <div className="min-w-0 flex-auto">
                                <p className="text-sm font-semibold leading-6 text-white">
                                    {club.fields.name}
                                </p>
                                <p className="mt-1 truncate text-xs leading-5 text-gray-400">
                                    {club.fields.address}
                                </p>
                            </div>
                        </div>
                        <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                            <p className="text-sm leading-6 text-white">
                                {club.fields.city_name}
                            </p>
                            <p className="mt-1 truncate text-xs leading-5 text-gray-400">
                                {club.fields.country_name}
                            </p>
                            {/*<p className='mt-1 text-xs leading-5 text-gray-400'>*/}
                            {/*    Last seen <time dateTime={person.lastSeenDateTime}>{person.lastSeen}</time>*/}
                            {/*</p>*/}
                        </div>
                    </li>
                ))}
        </ul>
    );
}
